import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';

export default function ConfiguracoesWhats() {
  const [token, setToken] = useState('');
  const [url, setUrl] = useState('');
  const [codigo, setCodigo] = useState('');
  const [id, setId] = useState('');
  const [celular, setCelular] = useState('');

  const [enviando, setEnviando] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setEnviando(true);
      const resultado = await api.get('/api/configuracoes');

      setEnviando(false);

      setUrl(resultado?.data[0]?.url);
      setToken(resultado?.data[0]?.token);
      setCodigo(resultado?.data[0]?.codigo);
      setId(resultado?.data[0]?.id);
    };

    getData();
  }, []);

  const onUpdate = async (e) => {
    e.preventDefault();

    setEnviando(true);

    try {
      const resultado = await api.post('/api/configuracoes', {
        token,
        url,
        codigo,
        id,
      });

      if (resultado) {
        toast.success('Configurações salva com sucesso', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
      }
    } catch (error) {
      toast.error('Error ao salvar configurações');
    }

    setEnviando(false);
  };

  const onTest = async (e) => {
    e.preventDefault();
    setEnviando(true);

    try {
      const resultado = await api.post('/api/configuracoes/teste', {
        celular,
      });

      if (resultado) {
        toast.success('Mensagem enviada com sucesso');
      }
    } catch (error) {
      toast.error('Error enviar menssagem');
    }

    setEnviando(false);
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Configuração Whats</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={onUpdate}>
          <label htmlFor="url">URL</label>
          <input
            type="text"
            id="url"
            name="text"
            onChange={(e) => setUrl(e.target.value)}
            placeholder="URL"
            value={url}
          />
          <label htmlFor="token">Token</label>
          <input
            type="text"
            id="token"
            name="text"
            onChange={(e) => setToken(e.target.value)}
            placeholder="Token"
            value={token}
          />{' '}
          <label htmlFor="codigo">Codigo</label>
          <input
            type="text"
            id="codigo"
            name="text"
            onChange={(e) => setCodigo(e.target.value)}
            value={codigo}
          />{' '}
          <button className="btn-primario" type="submit">
            {enviando ? 'MANDANDO...' : 'MANDAR'}
          </button>
        </form>
      </div>
      <div>
        <h4 className="pt-3 pb-3 f-black">Teste Whats</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={onTest}>
          <label htmlFor="celuar">
            Celular ( digite apenas numeros sem o digito do pais )
          </label>
          <input
            type="text"
            id="codigo"
            name="celuar"
            onChange={(e) => setCelular(e.target.value)}
            value={celular}
          />{' '}
          <button className="btn-primario" type="submit">
            {enviando ? 'MANDANDO...' : 'MANDAR'}
          </button>
        </form>
      </div>
    </Container>
  );
}
