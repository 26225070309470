import React from 'react';
import { Container } from 'react-bootstrap';
import { Search } from 'react-bootstrap-table2-toolkit';

const { SearchBar } = Search;

// const ExportCSV = (props) => {
//   return (
//     <div>
//       <CSVLink
//         data={props.data}
//         separator={';'}
//         filename={`Relatorio${moment().format('YYYYMMDDHHmmss')}.csv`}
//         headers={[
//           { label: 'Rede', key: 'rede' },
//           { label: 'Período', key: 'periodo' },
//           { label: 'Pts. Utilizados', key: 'pontosUtilizados' },
//           { label: 'Valor Ponto', key: 'valorPonto' },
//           { label: 'R$ a pagar de pts', key: 'aPagar' },
//           { label: 'Taxa de Sucesso', key: 'taxaSucesso' },
//           { label: 'Valor Total', key: 'valorTotal' },
//         ]}
//       >
//         <button className="btn-primario-slim ml-2">
//           <i className="fa fa-file-download px-2"></i>
//         </button>
//       </CSVLink>
//     </div>
//   );
// };

export default function Relatorio() {
  // const columns = [
  //   {
  //     dataField: 'id',
  //     text: 'ID',
  //     sort: true,
  //     hidden: true,
  //   },
  //   {
  //     dataField: 'rede',
  //     text: 'Rede',
  //     sort: true,
  //     footer: (columnData) => null,
  //   },
  //   {
  //     dataField: 'periodo',
  //     text: 'Período',
  //     sort: true,
  //     formatter: (celula, valor) => {
  //       return `${valor.mes}/${valor.ano}`;
  //     },
  //     footer: (columnData) => null,
  //   },
  //   {
  //     dataField: 'pontosUtilizados',
  //     text: 'Pts. Utilizados',
  //     sort: true,
  //     formatter: (celula, valor) => {
  //       return Number(valor.pontosUtilizados).toLocaleString('pt-BR');
  //     },
  //     footer: (columnData) => null,
  //     sortFunc: sortFunc,
  //   },
  //   {
  //     dataField: 'valorPonto',
  //     text: 'Valor Ponto',
  //     sort: true,
  //     formatter: (celula, valor) => {
  //       return Number(valor.valorPonto)
  //         .toLocaleString('pt-BR', {
  //           style: 'currency',
  //           currency: 'BRL',
  //         })
  //         .toString()
  //         .replace(/\u00a0/g, ' ');
  //     },
  //     sortFunc: sortFunc,
  //     footer: (columnData) => null,
  //   },
  //   {
  //     dataField: 'aPagar',
  //     text: 'R$ a pagar de pts',
  //     sort: true,
  //     formatter: (celula, valor) => {
  //       return Number(valor.aPagar)
  //         .toLocaleString('pt-BR', {
  //           style: 'currency',
  //           currency: 'BRL',
  //         })
  //         .toString()
  //         .replace(/\u00a0/g, ' ');
  //     },
  //     sortFunc: sortFunc,
  //     footer: (columnData) => null,
  //   },
  //   {
  //     dataField: 'taxaSucesso',
  //     text: 'Taxa de Sucesso',
  //     sort: true,
  //     formatter: (celula, valor) => {
  //       return `${Number(valor.taxaSucesso)
  //         .toLocaleString('pt-BR', {
  //           style: 'currency',
  //           currency: 'BRL',
  //         })
  //         .toString()
  //         .replace(/\u00a0/g, ' ')}`;
  //     },
  //     sortFunc: sortFunc,
  //     footer: (columnData) => 'Total',
  //   },
  //   {
  //     dataField: 'valorTotal',
  //     text: 'Valor Total',
  //     sort: true,
  //     formatter: (celula, valor) => {
  //       return `${Number(valor.valorTotal)
  //         .toLocaleString('pt-BR', {
  //           style: 'currency',
  //           currency: 'BRL',
  //         })
  //         .toString()
  //         .replace(/\u00a0/g, ' ')}`;
  //     },
  //     sortFunc: sortFunc,
  //     footer: (columnData) =>
  //       data
  //         .reduce((acc, item) => Number(acc) + Number(item.valorTotal), 0)
  //         .toLocaleString('pt-BR', {
  //           style: 'currency',
  //           currency: 'BRL',
  //         })
  //         .toString()
  //         .replace(/\u00a0/g, ' '),
  //   },
  // ];

  // const meses = [
  //   'Janeiro',
  //   'Fevereiro',
  //   'Março',
  //   'Abril',
  //   'Maio',
  //   'Junho',
  //   'Julho',
  //   'Agosto',
  //   'Setembro',
  //   'Outubro',
  //   'Novembro',
  //   'Dezembro',
  // ];

  // useEffect(() => {
  //   buscarRedes();
  // }, []);

  // const buscarRedes = async () => {
  //   const resultado = await api.get('/api/redes?ativo=1');
  //   if (resultado) {
  //     setRedesSelect(resultado.data.redes);
  //   }
  // };

  // const buscarRelatorio = async () => {
  //   setBuscando(true);
  //   const resultado = await api.get(
  //     `/api/relatorio?rede=${redeSelecionada}&ano=${anoSelecionado}&mes=${(
  //       Number(mesSelecionado) + 1
  //     )
  //       .toString()
  //       .padStart(2, '0')}`
  //   );
  //   if (resultado) {
  //     setData(resultado.data.relatorios);
  //   }
  //   setBuscando(false);
  // };

  // const [data, setData] = useState([]);
  // const [dataFormated, setDataFormated] = useState([]);
  // const [anoSelecionado, setAnoSelecionado] = useState(moment().year());
  // const [redeSelecionada, setRedeSelecionada] = useState('');
  // const [mesSelecionado, setMesSelecionado] = useState(moment().month() - 1);
  // const [redesSelect, setRedesSelect] = useState([]);
  // const [buscando, setBuscando] = useState(false);

  // useEffect(() => {
  //   if (buscando) {
  //     setData([]);
  //   }
  // }, [buscando]);

  // useEffect(() => {
  //   if (data) {
  //     setDataFormated(
  //       data.map((d) => ({
  //         rede: d.rede,
  //         periodo: `${d.ano}${d.mes}`,
  //         pontosUtilizados: Number(d.pontosUtilizados).toLocaleString('pt-BR'),
  //         valorPonto: Number(d.valorPonto)
  //           .toLocaleString('pt-BR', {
  //             style: 'currency',
  //             currency: 'BRL',
  //           })
  //           .toString()
  //           .replace(/\u00a0/g, ' '),
  //         aPagar: Number(d.aPagar)
  //           .toLocaleString('pt-BR', {
  //             style: 'currency',
  //             currency: 'BRL',
  //           })
  //           .toString()
  //           .replace(/\u00a0/g, ' '),
  //         taxaSucesso: Number(d.taxaSucesso)
  //           .toLocaleString('pt-BR', {
  //             style: 'currency',
  //             currency: 'BRL',
  //           })
  //           .toString()
  //           .replace(/\u00a0/g, ' '),
  //         valorTotal: Number(d.valorTotal)
  //           .toLocaleString('pt-BR', {
  //             style: 'currency',
  //             currency: 'BRL',
  //           })
  //           .toString()
  //           .replace(/\u00a0/g, ' '),
  //       }))
  //     );
  //   }
  // }, [data]);

  // const options = {
  //   sizePerPage: 10,
  //   showTotal: true,
  //   sizePerPageList: [
  //     {
  //       text: '5',
  //       value: 5,
  //     },
  //     {
  //       text: '10',
  //       value: 10,
  //     },
  //     {
  //       text: '50',
  //       value: 50,
  //     },
  //     {
  //       text: '100',
  //       value: 100,
  //     },
  //     {
  //       text: 'Todos',
  //       value: data.length,
  //     },
  //   ],
  //   paginationTotalRenderer: (from, to, size) =>
  //     size > 0 ? (
  //       <span className="react-bootstrap-table-pagination-total px-2">
  //         {from}-{to} de {size} resultados
  //       </span>
  //     ) : (
  //       <span className="react-bootstrap-table-pagination-total px-2">
  //         Nenhum resultado
  //       </span>
  //     ),
  // };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Relatório</h4>
      <hr className="hr-loja mt-0 mb-4" />
      {/* <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="anoSelecionado">Ano</label>
          <select
            className="input-select mb-3"
            name="anoSelecionado"
            id="anoSelecionado"
            onChange={(e) => setAnoSelecionado(e.target.value)}
            defaultValue={anoSelecionado}
          >
            {(() => {
              let anosSelect = [];
              for (let l = 2020; l <= moment().year(); l++) {
                anosSelect.push(<option value={l}>{l}</option>);
              }
              return anosSelect;
            })()}
          </select>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="mesSelecionado">Mês</label>
          <select
            className="input-select mb-3"
            name="mesSelecionado"
            id="mesSelecionado"
            onChange={(e) => setMesSelecionado(e.target.value)}
            defaultValue={mesSelecionado}
          >
            {(() => {
              let mesesSelect = [];
              if (anoSelecionado === 2020) {
                for (var j = 6; j <= moment().month() - 1; j++) {
                  mesesSelect.push(<option value={j}>{meses[j]}</option>);
                }
              } else {
                for (var m = 1; m <= moment().month() - 1; m++) {
                  mesesSelect.push(<option value={m}>{meses[m]}</option>);
                }
              }
              return mesesSelect;
            })()}
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={buscarRelatorio}>
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row> */}
      <div className="w-100 h-100">
        <iframe
          title="american-burrs"
          // width="1140"
          width="100%"
          // height="541.25"
          height="625"
          src="https://app.powerbi.com/reportEmbed?reportId=259fba37-b223-4176-ba13-eadb6367f362&autoAuth=true&ctid=7b19ce6b-5f49-41c9-bbae-e1c12fcd0b0a"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>

        {/* <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          exportCSV
          search
          bootstrap4
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <ExportCSV {...props.csvProps} data={dataFormated} />
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider> */}
      </div>
    </Container>
  );
}
