import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles.css';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { desautenticarParceiro } from '../../../store/modules/autenticacao/actions';
import Nav from '../../../components/Loja/Nav';
import NavMobile from '../../../components/Loja/NavMobile';

export default function Header(props) {
  const dispatch = useDispatch();
  const parceiro = useSelector((state) => {
    return state.parceiro;
  });

  const autenticado = useSelector(
    (state) => state.autenticacao.autenticadoLoja
  );

  const sair = () => {
    dispatch(desautenticarParceiro());
  };

  const saldoPontos = Number(parceiro.saldoPontos);

  return (
    <header className={props.auto ? 'header-home-auto' : 'header-home'}>
      <Container className="d-flex flex-column justify-content-center align-items-center text-center h-100">
        {!autenticado ? (
          <Row className="w-100">
            <Col xs={12} sm={8} className="m-auto p-2">
              <span>Clube Gaya</span>
            </Col>
            <Col xs={12} sm={4} className="m-auto p-2">
              <Link to="login">
                <i className="fas fa-user"></i> Entrar
              </Link>
            </Col>
          </Row>
        ) : (
          <>
            <Row className="d-flex d-md-none w-100">
              <NavMobile />
            </Row>
            <Row className="w-100 p-3">
              <Col md={2}>
                <Link to="/loja">
                  <img
                    className="d-none d-md-block nav-logo"
                    src="/imagens/logo_gaya_negativo.png"
                    alt="Logo Clube Gaya"
                  ></img>
                </Link>
              </Col>
              <Col xs={8} md={8} className="m-auto">
                <span>
                  Olá, <b>{parceiro.nome}</b>! Seu saldo atual é de{' '}
                  <b>
                    {isNaN(saldoPontos)
                      ? 0
                      : saldoPontos.toLocaleString('pt-BR')}
                  </b>{' '}
                  pontos.
                </span>
              </Col>
              <Col xs={4} md={2} className="m-auto">
                <button onClick={sair} className="nav-sair">
                  <i className="fas fa-sign-out-alt" /> Sair
                </button>
              </Col>
            </Row>
            <Row className="d-none d-md-flex w-100">
              <Nav />
            </Row>
          </>
        )}
      </Container>
    </header>
  );
}
