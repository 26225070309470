import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
  autenticacaoSucessoParceiro,
  autenticacaoSucessoUsuario,
  autenticacaoFalhouUsuario,
  autenticacaoFalhouParceiro,
  atualizarAntifraude,
} from './actions';
import historico from '../../../services/Historico';
import api from '../../../services/API';
import { toast } from 'react-toastify';

export function* autenticarUsuario({ payload }) {
  try {
    const { cpf, senha } = payload;

    const response = yield call(api.post, '/api/sessoes/usuario', {
      cpf,
      senha,
    });

    const { redesAntifraude, token, usuario } = response.data;

    api.defaults.headers.Authorization = `|${token}`;

    yield put(autenticacaoSucessoUsuario(token, usuario));
    yield put(atualizarAntifraude(redesAntifraude));

    historico.push('/painel/dashboard');
    toast.success(`Olá, ${usuario.nome}!`, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  } catch (err) {
    yield put(autenticacaoFalhouUsuario());
  }
}

export function* autenticarParceiro({ payload }) {
  try {
    const { cpf, senha } = payload;

    const response = yield call(api.post, '/api/sessoes/parceiro', {
      cpf,
      senha,
    });

    const { token, parceiro } = response.data;

    api.defaults.headers.Authorization = `${token}|`;

    yield put(autenticacaoSucessoParceiro(token, parceiro));

    historico.push('/loja');
    toast.success(`Olá, ${parceiro.nome}!`, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  } catch (err) {
    yield put(autenticacaoFalhouParceiro());
  }
}

export function setarToken({ payload }) {
  if (!payload) return;
  const { tokenPainel, tokenLoja } = payload.autenticacao;

  api.defaults.headers.Authorization = `${tokenLoja}|${tokenPainel}`;
}

export function atualizarAntifraudePosLiberacao({ payload }) {
  if (!payload) return;
  const { redesAntifraude } = payload;
  put(atualizarAntifraude(redesAntifraude));
}

export function desautenticarUsuario() {
  historico.push('/painel');
  toast.success(`Até mais!`, {
    position: toast.POSITION.BOTTOM_CENTER,
  });
}

export function desautenticarParceiro() {
  historico.push('/');
  toast.success(`Até mais!`, {
    position: toast.POSITION.BOTTOM_CENTER,
  });
}

export function desautenticarTodos() {
  if (historico.location.pathname.includes('painel')) {
    historico.push('/painel');
  } else {
    historico.push('/');
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setarToken),
  takeLatest('@autenticacaoUsuario/AUTENTICAR', autenticarUsuario),
  takeLatest('@autenticacaoUsuario/DESAUTENTICAR', desautenticarUsuario),
  takeLatest('@autenticacaoParceiro/AUTENTICAR', autenticarParceiro),
  takeLatest('@autenticacaoParceiro/DESAUTENTICAR', desautenticarParceiro),
  takeLatest(
    '@autenticacaoUsuario/ATUALIZAR_ANTIFRAUDE',
    atualizarAntifraudePosLiberacao
  ),
  takeLatest('@autenticacao/DESAUTENTICAR', desautenticarTodos),
]);
